import {
  ComponentFactoryResolver,
  HostBinding,
  Injectable,
  Renderer2,
  RendererFactory2,
  Type,
  ViewContainerRef
} from '@angular/core';
import { first, filter, mapTo } from 'rxjs/operators';

import { DialogModel } from 'models/dialog/dialog.model';
import { CustomDialogComponent } from 'models/dialog/custom-dialog-component.model';
import { isOwnClick } from 'studio/app/common/utils/owner-click.function';
import {BasicDialogComponent} from "ui/lib/components/dialog/basic/basic.component";

@Injectable()
export class DialogService {
  private dialogContainer!: ViewContainerRef | any;
  private renderer: Renderer2;
  constructor(
    private resolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public setDialogContainer(dialogContainer: ViewContainerRef): void {
    this.dialogContainer = dialogContainer;
  }

  public async addDialog(dialog: DialogModel): Promise<boolean | void> {
    const factory = this.resolver.resolveComponentFactory(BasicDialogComponent);
    const dialogComponent = factory.create(this.dialogContainer.injector);
    dialogComponent.instance.dialog = dialog;
    this.dialogContainer.insert(dialogComponent.hostView);
    document.body.style.overflow = 'hidden';
    const result = await dialogComponent.instance.dialogResult.pipe(first()).toPromise();
    document.body.style.overflow = 'unset';

    dialogComponent.destroy();
    return result;
  }

  public async addCustomDialog<DialogResult, DialogParams>(
    component: Type<CustomDialogComponent<DialogResult, DialogParams>>,
    params: DialogParams,
    isHoldScroll = false,
    drag = false
  ): Promise<DialogResult> {
    const factory = this.resolver.resolveComponentFactory(component);
    const dialogComponent = factory.create(this.dialogContainer.injector);
    dialogComponent.instance.params = params;

    dialogComponent.instance.outsideClick$ = isOwnClick(dialogComponent.location.nativeElement).pipe(
      filter((ownClick) => !ownClick),
      mapTo(undefined),
    );

    this.dialogContainer.insert(dialogComponent.hostView);

    if (drag) {
      this.renderer.setAttribute(this.dialogContainer._hostLView[0], 'class', 'drag');
    }

    if (!isHoldScroll) {
      document.body.style.overflow = 'hidden';
    }

    const result = await dialogComponent.instance.dialogResult.pipe(first()).toPromise();

    if (!isHoldScroll) {
      document.body.style.overflow = 'unset';
    }
    this.renderer.removeClass(this.dialogContainer._hostLView[0], 'drag');
    dialogComponent.destroy();
    return result;
  }
}
