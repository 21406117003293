import { Component } from '@angular/core';
import { BreadCrumbsService } from '../../services/bread-crumbs.service';
import { SafeTypes } from 'ui';

@Component({
  selector: 'nav[data-component-name="bread-crumbs"]',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss'],
})
export class BreadCrumbsComponent {
  public segments$ = this.breadCrumbsService.getSegments();

  public SafeTypes = SafeTypes;

  constructor(private breadCrumbsService: BreadCrumbsService) {}
}
