import { TRANSLOCO_CONFIG, translocoConfig, TranslocoModule, TRANSLOCO_LOADER } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { LanguagesList, DefaultLang, AvailableLangs } from './consts/languages-list.const';
import { HttpLoader } from './services/lang-loader.service';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  exports: [TranslocoModule],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        /** https://github.com/libyal/libfwnt/wiki/Language-Code-identifiers */
        [AvailableLangs.ar]: 'ar-AE',
        [AvailableLangs.cs]: 'cs-CZ',
        [AvailableLangs.en]: 'en-US',
        [AvailableLangs.es]: 'es-ES',
        [AvailableLangs.fr]: 'fr-FR',
        [AvailableLangs.ja]: 'ja-JP',
        [AvailableLangs.pt]: 'pt-PT',
        [AvailableLangs.ru]: 'ru-RU',
        [AvailableLangs.tr]: 'tr-TR',
        [AvailableLangs.uk]: 'uk-UA',
      },
    }),
  ],
  providers: [
    { provide: TRANSLOCO_LOADER, useClass: HttpLoader },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: LanguagesList,
        defaultLang: localStorage.getItem('lang') || DefaultLang,
        fallbackLang: DefaultLang,
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          useFallbackTranslation: true,
        },
      }),
    },
  ],
})
export class LocalizationModule {}
