import { Injectable } from '@angular/core';
import { environment } from 'sportshub/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Currency } from 'models/common/currency.model';

@Injectable({
  providedIn: 'root',
})
export class DictionariesController {
  constructor(private http: HttpClient) {}

  public getCurrencies() {
    /** TODO: disable vesion control in backend */
    return this.http.get<Currency[]>(`${environment.studioApi}api/v1/dictionary/currency`, { headers: { appversion: '200' } });
  }
}
