import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MainComponent } from './components/main/main.component';
import { HeaderComponent } from './components/header/header.component';
import { LocalizationModule } from 'localization';
import { UiModule } from 'ui';
import { SidebarLeftComponent } from './components/sidebar-left/sidebar-left.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { FooterComponent } from './components/footer/footer.component';
import { CoreRoutingModule } from './core-routing.module';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from 'sportshub/environments/environment';
import { DictionariesState } from '../dictionaries/dictionaries.state';
import { BreadCrumbsComponent } from './components/bread-crumbs/bread-crumbs.component';
import { OverlayComponent } from 'sportshub/app/core/components/overlay/overlay.component';
import {DialogService} from "ui/lib/services/dialog.service";
import {DialogWrapperComponent} from "ui/lib/components/dialog/wrapper/wrapper.component";

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    SidebarLeftComponent,
    SidebarRightComponent,
    FooterComponent,
    MainMenuComponent,
    BreadCrumbsComponent,
    OverlayComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    CoreRoutingModule,
    LocalizationModule,
    BrowserAnimationsModule,
    UiModule,
    NgxsModule.forRoot([DictionariesState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  ],
  providers: [
    DialogService
  ],
  bootstrap: [
    HeaderComponent,
    MainComponent,
    SidebarLeftComponent,
    SidebarRightComponent,
    FooterComponent,
    OverlayComponent,
    DialogWrapperComponent
  ],
})
export class CoreModule {}
