import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { LocalSelectorComponent } from './components/local-selector/local-selector.component';
import { Controls } from './controls';
import { OutsideClickDirective } from './directives/outside-click.directive';

import { MatSelectModule } from '@angular/material/select';
import { TextFittingDirective } from './directives/text-fitting.directive';
import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './services/toast.service';
import { SafePipe } from 'ui/lib/pipes/safe.pipe';
import {MoneyHelper} from "ui/lib/services/money-helper.service";
import {DialogWrapperComponent} from "ui/lib/components/dialog/wrapper/wrapper.component";
import {BasicDialogComponent} from "ui/lib/components/dialog/basic/basic.component";
import {LightboxComponent} from "ui/lib/components/lightbox/lightbox.component";
import {CurrencySymbolPipe} from "ui/lib/pipes/currency-symbol.pipe";
import {TaxPricePipe} from "ui/lib/pipes/tax-price.pipe";
import {ToastrModule} from "ngx-toastr";
import {MatTooltipModule} from "@angular/material/tooltip";
import {HelperService} from "ui/lib/services/helper.service";
import { ImgSlideComponent } from './components/img-slide/img-slide.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    Controls,
    LocalSelectorComponent,
    OutsideClickDirective,
    SafePipe,
    TextFittingDirective,
    ToastComponent,
    LightboxComponent,
    DialogWrapperComponent,
    BasicDialogComponent,
    CurrencySymbolPipe,
    TaxPricePipe,
    ImgSlideComponent
  ],
  imports: [FormsModule, ReactiveFormsModule, FontAwesomeModule, CommonModule, TranslocoModule, MatSelectModule, CarouselModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      autoDismiss: true,
    }),
  ],
  exports: [
    Controls,
    ReactiveFormsModule,
    FontAwesomeModule,
    LocalSelectorComponent,
    OutsideClickDirective,
    MatSelectModule,
    SafePipe,
    TextFittingDirective,
    DialogWrapperComponent,
    LightboxComponent,
    CurrencySymbolPipe,
    TaxPricePipe,
    MatTooltipModule,
  ],
  entryComponents: [
    DialogWrapperComponent,
    BasicDialogComponent,
  ],
  providers: [ToastService, MoneyHelper, HelperService],
  bootstrap: [DialogWrapperComponent]
})
export class UiModule {}
