import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { filter, takeUntil } from 'rxjs/operators';

import { isOwnClick } from 'utils';
import { Animations, OptionListStates } from './dropdown.animations';
import { Subject } from 'rxjs';

interface OptionValue {
  [key: string]: string;
}

@Component({
  selector: 'ui-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: Animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('attr.tabindex')
  private tabindex = 0;

  @Input()
  public dataKey = 'id';

  @Input()
  public optionLabel = 'name';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('list')
  private set changeList(optionList: OptionValue[]) {
    if (!Array.isArray(optionList)) {
      return;
    }

    this.optionList = optionList;
    this.applyFilter(this.filterControl.value);
  }

  @Input()
  public isShowFilter = false;

  @Input()
  public isMultiSelect = false;

  @Input()
  public maxVisibleOptionsNumber = 5;

  @Input()
  public isShowClearButton = false;

  @ViewChild('filter')
  private filterElement!: ElementRef<HTMLInputElement>;

  @Input()
  public placeholder: string | null = '';

  public filterControl = new FormControl('');

  private optionList: OptionValue[] = [];

  public filteredList: OptionValue[] = [];

  public selectedItem: OptionValue[] = [];

  @HostBinding('attr.state')
  public optionListState: OptionListStates = OptionListStates.hide;

  @HostBinding('class.disable')
  public disabled = false;

  protected unsubscriber$: Subject<void> = new Subject();

  constructor(private sanitizer: DomSanitizer, private dropdownElementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

  public get selectedItemText(): string {
    return this.selectedItem.map((item) => item[this.optionLabel]).join(', ');
  }

  public ngOnInit(): void {
    this.subscribeToFilter();

    if (!this.placeholder) {
      this.placeholder = 'Select';
    }
  }

  public ngAfterViewInit(): void {
    this.subscribeToOutsideClick();
  }

  public ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange = (value: unknown) => undefined;
  onTouched = () => undefined;
  public registerOnTouched(fn: () => undefined): void {
    this.onTouched = fn;
  }

  public registerOnChange(fn: () => undefined): void {
    this.onChange = fn;
  }

  public clearSelectedOption(event: MouseEvent): void {
    event.stopPropagation();
    this.onTouched();
    this.selectedItem = [];

    if (this.isMultiSelect) {
      this.onChange([]);
    } else {
      this.onChange(null);
    }

    this.optionListState = OptionListStates.hide;
  }

  public selectOption(option: OptionValue): void {
    this.onTouched();
    if (this.isMultiSelect) {
      const isBeforeSeletedOption = this.selectedItem.some((item) => item[this.dataKey] === option[this.dataKey]);
      this.selectedItem = isBeforeSeletedOption
        ? this.selectedItem.filter((item) => item[this.dataKey] !== option[this.dataKey])
        : [...this.selectedItem, option];
    } else {
      this.selectedItem = [option];
    }

    if (this.isMultiSelect) {
      this.onChange(this.selectedItem);
    } else {
      this.onChange(this.selectedItem[0]);
    }

    if (!this.isMultiSelect) {
      this.optionListState = OptionListStates.hide;
    }
  }

  public isSelecteditem(option: OptionValue): boolean {
    return this.selectedItem.some((item) => item[this.dataKey] === option[this.dataKey]);
  }

  public writeValue(rawValue: unknown): void {
    if (!Array.isArray(rawValue) && String(rawValue) !== '[object Object]') {
      this.selectedItem = [];
      return;
    }

    const values = (Array.isArray(rawValue) ? rawValue : [rawValue]).filter((value) => {
      const fieldaNames = Object.keys(value);

      if (!fieldaNames.includes(this.optionLabel) || !fieldaNames.includes(this.dataKey)) {
        console.warn('Значение для выпадающего списка не соответствует заданному списку возможных значений');
        return false;
      }

      return true;
    });

    this.selectedItem = values;
  }

  public toggleOptionListState(): void {
    if (this.disabled) {
      return;
    }

    this.optionListState = this.optionListState === OptionListStates.hide ? OptionListStates.show : OptionListStates.hide;

    if (this.isShowFilter) {
      this.filterControl.patchValue('');
      this.filterElement.nativeElement.focus();
    }
  }

  public get maxVisibleOptionsCount(): SafeStyle {
    const currentCount = this.optionList.length;
    const maxCount = currentCount > this.maxVisibleOptionsNumber ? this.maxVisibleOptionsNumber : currentCount;
    return this.sanitizer.bypassSecurityTrustStyle(`--max-options-count: ${maxCount}`);
  }

  public get optionsCount(): SafeStyle {
    const currentCount = this.optionList.length;
    const maxCount = currentCount > this.maxVisibleOptionsNumber ? this.maxVisibleOptionsNumber : currentCount;
    return this.sanitizer.bypassSecurityTrustStyle(`--visible-options-count: ${currentCount >= maxCount ? maxCount : currentCount}`);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.optionListState = OptionListStates.hide;
  }

  public get isHasSelectedItem(): boolean {
    return Boolean(this.selectedItem.length);
  }

  private subscribeToFilter(): void {
    if (!this.isShowFilter) {
      return;
    }

    this.filterControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe((filterText: string) => {
      this.applyFilter(filterText);
    });
  }

  private applyFilter(filterText: string): void {
    const preparedFilterText = filterText.toLowerCase();
    this.filteredList = this.optionList.filter((option) => String(option[this.optionLabel]).toLowerCase().includes(preparedFilterText));
  }

  private subscribeToOutsideClick(): void {
    isOwnClick(this.dropdownElementRef.nativeElement)
      .pipe(filter((isInDropdownClick) => !isInDropdownClick))
      .subscribe(() => {
        this.optionListState = OptionListStates.hide;
        this.changeDetectorRef.markForCheck();
      });
  }
}
