import { animate, state, style, transition, trigger } from '@angular/animations';

export enum OptionListStates {
  hide = 'hide',
  show = 'show',
}

export const Animations = [
  trigger('optionListSate', [
    state(
      OptionListStates.hide,
      style({
        opacity: '0',
        height: '0',
        'z-index': '-1',
        'pointer-events': 'none',
      }),
    ),
    state(
      OptionListStates.show,
      style({
        opacity: '1',
        height: '*',
        'z-index': '10',
        'pointer-events': 'unset',
      }),
    ),

    transition('hide <=> show', [animate('0.2s ease-out')]),
  ]),
];
