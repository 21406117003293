import { Injectable } from '@angular/core';
import { Tax } from 'models/tax/tax.model';
import { Studio } from 'models/studio/studio.model';
import { StoreProduct } from 'models/store/store-product.model';
import { StoreStockItem } from 'models/store/store-stock-item.model';

@Injectable()
export class MoneyHelper {
  public tax(studio: Studio | null, product: StoreStockItem | StoreProduct): number {
    if (!studio || !product.isTaxable) {
      return 0;
    }

    return studio.taxes.reduce((total: number, currentTax: Tax) => total + currentTax.percentageDiscount, 0);
  }
}
