import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { SidebarService } from 'sportshub/app/core/services/sidebar.services';

@Component({
  selector: 'aside[data-component-name="sidebar-right"]',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss'],
})
export class SidebarRightComponent implements AfterViewInit {
  @ViewChild('sidebarComponentOutlet', { read: ViewContainerRef, static: true })
  private sidebarComponentOutlet!: ViewContainerRef;

  constructor(private sidebarService: SidebarService) {}

  public ngAfterViewInit(): void {
    this.sidebarService.setSidebarContainer(this.sidebarComponentOutlet, 'right');
  }
}
