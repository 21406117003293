<div class="ad-app">
  <span class="download-our-app">{{ 'DOWNLOAD_OUR_APP' | transloco }}</span>
  <a class="badge" target="_blank" [href]="linkIOSApp"><img src="/static/app-store-badge.png" /></a>
  <a class="badge" target="_blank" [href]="linkAndroidApp"><img src="/static/google-play-badge.png" /></a>
</div>

<div class="wrap-footer-info">
  <div class="footer-info">
    <a class="mobile-logo logo" routerLink="/"><img src="/static/logo-line.svg" title="" /></a>

    <div class="nav-columns">
      <nav class="desktop blue-links">
        <a class="logo" routerLink="/"><img src="/static/logo-line.svg" title="" /></a>
        <ng-container *ngTemplateOutlet="links" class="desktop"></ng-container>
      </nav>
      <nav>
        <span class="column-title">{{ 'INFORMATION' | transloco }}</span>
        <a>{{ 'HOME' | transloco }}</a>
        <a>{{ 'ABOUT_US' | transloco }}</a>
        <a>{{ 'PHOTOS' | transloco }}</a>
        <a>{{ 'VIDEOS' | transloco }}</a>
        <a>{{ 'CERTIFICATIONS' | transloco }}</a>
        <a>{{ 'NEWS' | transloco }}</a>
        <a>{{ 'LOCATIONS' | transloco }}</a>
        <a>{{ 'CONTACT_US' | transloco }}</a>
      </nav>
      <nav>
        <span class="column-title">{{ 'PRODUCTS_AND_SERVICES' | transloco }}</span>
        <a>{{ 'EVENTS' | transloco }}</a>
        <a [href]="studioLinkUrl" target="_blank">{{ 'STUDIOS' | transloco }}</a>
        <a>{{ 'VIRTUAL_STUDIOS' | transloco }}</a>
        <a>{{ 'DASHBOARD_AND_PRICING' | transloco }}</a>
        <a>{{ 'TRAVEL_AND_LOADING' | transloco }}</a>
      </nav>
      <nav>
        <span class="contact-title">{{ 'PHONE_NUMBER' | transloco }}</span>
        <a [href]="'tel:' + contactPhoneNumber">{{ contactPhoneNumber }}</a>
        <span class="contact-title">{{ 'EMAIL' | transloco }}</span>
        <a [href]="'mailto:' + contactEmail">{{ contactEmail }}</a>
        <div class="social-icons">
          <a target="_blank" href="https://www.facebook.com/MYuventex"><fa-icon [icon]="faFacebook"></fa-icon></a>
          <a target="_blank" href="https://www.youtube.com/channel/UC5pnyfSfNG6altM86pxtB3w"><fa-icon [icon]="faYoutube"></fa-icon></a>
          <a target="_blank" href="https://www.instagram.com/uventexlabs/"><fa-icon [icon]="faInstagram"></fa-icon></a>
        </div>
      </nav>

      <nav class="mobile blue-links">
        <ng-container *ngTemplateOutlet="links"></ng-container>
      </nav>

      <ng-template #links>
        <a target="_blank" [href]="privacyPolicyLink">{{ 'PRIVACY_POLICY' | transloco }}</a>
        <a routerLink="/cookie/statement">{{ 'COOKIES' | transloco }}</a>
      </ng-template>
    </div>
  </div>
</div>

<div class="copyright">Designed and created by Uventex 2021</div>
