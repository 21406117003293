import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvailableLangs, DefaultLang, LocaleLabels } from 'localization/lib/consts/languages-list.const';
import { TranslocoService } from '@ngneat/transloco';
import { IconArrowDown } from 'icons';
import { SafeTypes } from 'ui/lib/enums/safe-types.enum';

@Component({
  selector: 'ui-local-selector',
  templateUrl: './local-selector.component.html',
  styleUrls: ['./local-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalSelectorComponent {
  @Input()
  public isAdmin = false;

  public AvailableLangs = AvailableLangs;

  public IconArrowDown = IconArrowDown;
  public SafeTypes = SafeTypes;

  public LocaleLabels = LocaleLabels;

  public isShowMenu = false;

  constructor(
    private translocoService: TranslocoService
  ) {}

  public outsideClick(isOwnClick: boolean) {
    if (!isOwnClick) {
      this.isShowMenu = false;
    }
  }

  changeLocale(selectedLang: AvailableLangs) {
    if (selectedLang === AvailableLangs.raw) {
      this.translocoService.setDefaultLang(selectedLang);
      this.translocoService.setFallbackLangForMissingTranslation({ fallbackLang: selectedLang });
      this.translocoService.setActiveLang(selectedLang);
      this.isShowMenu = false;
      return;
    }

    if (this.translocoService.getDefaultLang() !== DefaultLang) {
      this.translocoService.setDefaultLang(DefaultLang);
      this.translocoService.setFallbackLangForMissingTranslation({ fallbackLang: DefaultLang });
    }

    localStorage.setItem('lang', selectedLang);
    this.translocoService.setActiveLang(selectedLang);
    this.isShowMenu = false;
  }
}
