<div class="menu" [ngClass]="{ open: menuServices.isOpenMenu }">
  <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="menu-item">
    <span class="icon" [innerHtml]="IconMain | safe: SafeTypes.html"></span>
    {{ 'MAIN' | transloco }}
  </a>
  <a class="menu-item" [href]="baseurl">
    <span class="icon" [innerHtml]="IconStarCircle | safe: SafeTypes.html"></span>
    {{ 'EVENTS' | transloco }}
  </a>
  <a class="menu-item" [href]="studioLinkUrl" target="_blank">
    <span class="icon" [innerHtml]="IconCupWinner | safe: SafeTypes.html"></span>
    {{ 'STUDIOS' | transloco }}
  </a>
  <a class="menu-item">
    <span class="icon" [innerHtml]="IconLamp | safe: SafeTypes.html"></span>
    {{ 'TRAINING' | transloco }}
  </a>
  <a class="menu-item">
    <span class="icon" [innerHtml]="IconStars | safe: SafeTypes.html"></span>
    {{ 'SEMINARS' | transloco }}
    <span class="soon">{{ 'SOON' | transloco }}</span>
  </a>
  <a class="menu-item">
    <span class="icon" [innerHtml]="IconChartVertical | safe: SafeTypes.html"></span>
    {{ 'RATINGS' | transloco }}
    <span class="soon">{{ 'SOON' | transloco }}</span>
  </a>
  <a class="menu-item">
    <span class="icon" [innerHtml]="IconProfile | safe: SafeTypes.html"></span>
    {{ 'ATHLETES' | transloco }}
    <span class="soon">{{ 'SOON' | transloco }}</span>
  </a>
  <a class="menu-item" routerLink="/store" routerLinkActive="active">
    <span class="icon" [innerHtml]="IconStore | safe: SafeTypes.html"></span>
    {{ 'STORE' | transloco }}
  </a>
  <a class="menu-item">
    <span class="icon" [innerHtml]="IconQuestionCircle | safe: SafeTypes.html"></span>
    {{ 'ABOUT_US' | transloco }}
  </a>
  <a class="menu-item">
    <span class="icon" [innerHtml]="IconAddMessage | safe: SafeTypes.html"></span>
    {{ 'SUPPORT' | transloco }}
  </a>
</div>
