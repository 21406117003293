import { Component } from '@angular/core';
import { SafeTypes } from 'ui';
import { IconCart } from 'icons';
import { StoreService } from 'libs/uv-store/src/services/store.service';
import { MenuServices } from 'sportshub/app/core/services/menu.services';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public IconCart = IconCart;
  public SafeTypes = SafeTypes;

  constructor(
    public storeService: StoreService,
    public menuServices: MenuServices
  ) {
  }
}
