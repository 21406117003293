import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private rightSidebarContainer = new BehaviorSubject<ViewContainerRef | null>(null);
  private leftSidebarContainer = new BehaviorSubject<ViewContainerRef | null>(null);

  constructor(private resolver: ComponentFactoryResolver) {}

  public setSidebarContainer(sidebarContainer: ViewContainerRef, side: 'left' | 'right'): void {
    if (side === 'left') {
      this.leftSidebarContainer.next(sidebarContainer);
    } else {
      this.rightSidebarContainer.next(sidebarContainer);
    }
  }

  public async addComponent<T>(component: Type<T>, side: 'left' | 'right', position: number | null = null): Promise<ComponentRef<T>> {
    const factory = this.resolver.resolveComponentFactory(component);
    const containerSubject = side === 'left' ? this.leftSidebarContainer : this.rightSidebarContainer;

    const container = (await containerSubject

      .pipe(
        filter((container) => container !== null),
        first(),
      )
      .toPromise()) as ViewContainerRef;

    const componentInstance = factory.create(container.injector);
    container.insert(componentInstance.hostView);

    if (position !== null) {
      container.move(componentInstance.hostView, position);
    }

    return componentInstance;
  }
}
