<span #label (click)="isShowMenu = !isShowMenu" class="label">{{ 'LANGUAGE_SELF_LABEL' | transloco }}</span>
<span class="icon" #icon2 (click)="isShowMenu = !isShowMenu" [innerHtml]="IconArrowDown | safe: SafeTypes.html"></span>

<div
  class="locales"
  [class.show]="isShowMenu"
  [class.is-admin]="isAdmin"
  uiOutsideClick
  [skipExcludes]="[label, icon2]"
  (outsideClick)="outsideClick($event)"
>
  <div
    class="locale"
    *ngFor="let locale of LocaleLabels"
    (click)="changeLocale(locale.locale)"
    [class.is-raw-locale]="AvailableLangs.raw === locale.locale"
  >
    {{ locale.label }}
  </div>
</div>
