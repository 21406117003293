import { Component } from '@angular/core';
import { environment } from 'sportshub/environments/environment';
import { faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public linkAndroidApp = environment.linkAndroidApp;
  public linkIOSApp = environment.linkIOSApp;
  public privacyPolicyLink = environment.PRIVACY_POLICY_LINK;
  public contactPhoneNumber = environment.contactPhoneNumber;
  public contactEmail = environment.contactEmail;
  public studioLinkUrl = environment.studioLinkUrl;

  public faFacebook = faFacebook;
  public faYoutube = faYoutube;
  public faInstagram = faInstagram;
}
