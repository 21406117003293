import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { SidebarService } from '../../services/sidebar.services';

@Component({
  selector: 'aside[data-component-name="sidebar-left"]',
  templateUrl: './sidebar-left.component.html',
  styleUrls: ['./sidebar-left.component.scss'],
})
export class SidebarLeftComponent implements AfterViewInit {
  @ViewChild('sidebarComponentOutlet', { read: ViewContainerRef, static: true })
  private sidebarComponentOutlet!: ViewContainerRef;

  constructor(private sidebarService: SidebarService) {}

  public ngAfterViewInit(): void {
    this.sidebarService.setSidebarContainer(this.sidebarComponentOutlet, 'left');
  }
}
