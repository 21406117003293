import { Component } from '@angular/core';
import { MenuServices } from 'sportshub/app/core/services/menu.services';

@Component({
  selector: 'overlay',
  template: `
    <div [ngClass]="{'overlay': menuServices.isOpenMenu}"></div>
  `,
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {
  constructor(
    public menuServices: MenuServices
  ) {}
}
