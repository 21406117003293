<div class="block">
  <div class="left">
    <div class="header-button" (click)="menuServices.switchMenu()">
      <svg *ngIf='!menuServices.isOpenMenu' width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="2" fill="#01A1DA"/>
        <rect y="7" width="22" height="2" fill="#01A1DA"/>
        <rect y="14" width="22" height="2" fill="#01A1DA"/>
      </svg>

      <svg *ngIf='menuServices.isOpenMenu' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.000976562" y="15.5566" width="22" height="2" transform="rotate(-45 0.000976562 15.5566)" fill="#01A1DA"/>
        <rect x="1.41418" width="22" height="2" transform="rotate(45 1.41418 0)" fill="#01A1DA"/>
      </svg>
    </div>
  </div>

  <div class="center">
    <div class="hello-block">{{ 'HELLO_TITLE' | transloco }}</div>
    <a class="logo" routerLink="/"><img src="/static/logo-line.svg" title="" /></a>
  </div>

  <div class="right">
    <div class="cart">
      <button [routerLink]="'/store/cart'">
        <a class="icon" [innerHTML]="IconCart | safe: SafeTypes.html"></a>
        <span class="count" *ngIf="storeService.numberOfItemsInStore()">{{storeService.numberOfItemsInStore()}}</span>
      </button>
    </div>
    <ui-local-selector></ui-local-selector>
  </div>
</div>
