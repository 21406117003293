import { Directive, Output, EventEmitter, AfterViewInit, ViewContainerRef, Input, OnDestroy } from '@angular/core';
import { isOwnClick } from 'utils';
import { Subscription, Observable } from 'rxjs';
import { first, filter } from 'rxjs/operators';

@Directive({
  selector: '[uiOutsideClick]',
})
export class OutsideClickDirective implements AfterViewInit, OnDestroy {
  @Output()
  private outsideClick = new EventEmitter<boolean>();

  @Input()
  public skipExcludes: unknown[] = [];

  @Input()
  public lazySkipExcludes: Observable<HTMLElement>[] = [];

  private allExcludes: HTMLElement[] = [];

  private clickSubscribe!: Subscription;

  constructor(private viewContainerRef: ViewContainerRef) {}

  public ngOnDestroy(): void {
    if (this.clickSubscribe) {
      this.clickSubscribe.unsubscribe();
    }
  }

  public ngAfterViewInit(): void {
    this.allExcludes = [...this.skipExcludes] as HTMLElement[];
    this.listenOutSideClick();

    this.lazySkipExcludes.forEach((lazySkipExcludes) => {
      lazySkipExcludes
        .pipe(
          filter((excludeElement) => !!excludeElement),
          first(),
        )
        .subscribe((excludeElement) => {
          this.allExcludes = [...this.allExcludes, excludeElement];
          this.listenOutSideClick();
        });
    });
  }

  private listenOutSideClick(): void {
    if (this.clickSubscribe) {
      this.clickSubscribe.unsubscribe();
    }

    this.clickSubscribe = isOwnClick(this.viewContainerRef.element.nativeElement, this.allExcludes).subscribe((isOwn) => {
      this.outsideClick.emit(isOwn);
    });
  }
}
