export const environment = {
  production: false,
  linkAndroidApp: 'https://play.google.com/store/apps/details?id=com.uventex.studio',
  linkIOSApp: 'https://apps.apple.com/app/myuventex-studio-management/id1465602154',
  PRIVACY_POLICY_LINK: 'http://devuventex.com/GWTClient/html/privacyPolicy.html',
  contactPhoneNumber: '+1 469-766-2095',
  contactEmail: 'support@uventex.com',
  studioApi: 'https://api.studio.devuventex.com/',
  studioLinkUrl: 'https://devuventex.com/studios/',
  STACK_PAY_ENV: 'sandbox',
  APIURL: 'https://api.studio.devuventex.com/',
  BASEURL: 'https://devuventex.com/',
};
