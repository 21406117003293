import { Component } from '@angular/core';
import {
  IconAddMessage,
  IconMain,
  IconChartVertical,
  IconCupWinner,
  IconLamp,
  IconProfile,
  IconQuestionCircle,
  IconStarCircle,
  IconStars,
  IconStore,
} from 'icons';
import { SafeTypes } from 'ui';
import { MenuServices } from 'sportshub/app/core/services/menu.services';
import { environment } from 'sportshub/environments/environment';

@Component({
  selector: 'nav[data-component-name="main-menu"]',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
  public IconAddMessage = IconAddMessage;
  public IconMain = IconMain;
  public IconChartVertical = IconChartVertical;
  public IconCupWinner = IconCupWinner;
  public IconLamp = IconLamp;
  public IconProfile = IconProfile;
  public IconQuestionCircle = IconQuestionCircle;
  public IconStarCircle = IconStarCircle;
  public IconStars = IconStars;
  public IconStore = IconStore;
  public studioLinkUrl = environment.studioLinkUrl;
  public baseurl = environment.BASEURL;

  public SafeTypes = SafeTypes;

  constructor(public menuServices: MenuServices) {}
}
