import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'cookie', loadChildren: () => import('sportshub/app/features/cookie/cookie.module').then((m) => m.CoockieModule) },
  { path: 'store', loadChildren: () => import('sportshub/app/features/store/store.module').then((m) => m.StoreModule) },
  { path: '**', redirectTo: '/store/list' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }), CommonModule],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
