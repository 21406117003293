import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreadCrumbSegment } from '../models/bread-crumb-segment.model';

@Injectable({ providedIn: 'root' })
export class BreadCrumbsService {
  private segments = new BehaviorSubject<BreadCrumbSegment[]>([]);

  public setSegments(segments: BreadCrumbSegment[]): void {
    this.segments.next(segments);
  }

  public getSegments(): Observable<BreadCrumbSegment[]> {
    return this.segments.asObservable();
  }
}
