import { Injectable } from '@angular/core';
import { StoreStockItems, StoreStockItem } from 'models/store/store-stock-item.model';
import { StoreAttribute } from 'models/store/store-attribute.model';
import { Subject } from 'rxjs';

interface NewItem extends StoreStockItem {
  studio: any;
  userId: number;
}

@Injectable({ providedIn: 'root' })
export class StoreService {
  public updatedStore = new Subject<void | StoreStockItems>();

  public addItemToCart(newItem: NewItem): void {
    const store: StoreStockItems | void = this.getStore;

    if (!store) {
      this.updateStore({ [newItem.studioId]: [newItem] });
      return;
    }

    if (store[newItem.studioId]) {
      for (const item of store[newItem.studioId]) {
        if (newItem?.attributes.length) {
          if (item?.attributes?.length === newItem.attributes.length) {
            const itemIsAlreadyInStore: StoreAttribute[] = item.attributes.filter(
              (attribute: StoreAttribute) => !newItem.attributes.find((newAttribute) => attribute.id === newAttribute.id),
            );

            if (!itemIsAlreadyInStore.length) {
              item.quantityProduct += newItem.quantityProduct;
              this.updateStore(store);
              return;
            }
          }
        } else if (item.productId === newItem.productId) {
          item.quantityProduct += newItem.quantityProduct;
          this.updateStore(store);
          return;
        }
      }

      store[newItem.studioId].push(newItem);
      this.updateStore(store);
      return;
    }

    this.updateStore(Object.assign({ [newItem.studioId]: [newItem] }, this.getStore));
  }

  public get getStore(): StoreStockItems | void {
    const store: any = localStorage.getItem('store_'+this.userId);
    if (store) {
      return JSON.parse(store);
    }
  }

  public get userId() {
    return JSON.parse(localStorage.getItem('authToken') as any)?.userId | 0;
  }

  public decrementItem(studioId: number, itemNumber: number): void {
    const store = this.getStore;
    if (store && store[studioId][itemNumber].quantityProduct) {
      store[studioId][itemNumber].quantityProduct--;
      this.updateStore(store);
    }
  }

  public incrementItem(studioId: number, itemNumber: number): void {
    const store = this.getStore;
    if (store && store[studioId][itemNumber].quantityProduct < store[studioId][itemNumber].quantity) {
      store[studioId][itemNumber].quantityProduct++;
      this.updateStore(store);
    }
  }

  public deleteItem(studioId: number, itemNumber: number) {
    const store = this.getStore;

    if (store) {
      store[studioId].splice(itemNumber, 1);
      if (!store[studioId].length) {
        delete store[studioId];
      }

      if (store && Object.keys(store).length) {
        this.updateStore(store);
      } else {
        this.deleteStore(studioId);
      }
    }
  }

  public deleteCheckedItemInStudio(studioId: number): void {
    const store = this.getStore;

    if (!store || !store[studioId]) {
      return;
    }

    const itemList = store[studioId].filter((item) => !item.isChecked);

    if (!itemList.length) {
      delete store[studioId];
    } else {
      store[studioId] = itemList;
    }

    this.updateStore(store);
  }

  public markProduct(studioId: number, itemNumber: number) {
    const store = this.getStore;
    if (store) {
      store[studioId][itemNumber].isChecked = !store[studioId][itemNumber].isChecked;
      this.updateStore(store);
    }
  }

  public deleteStore(studioId: number): void {
    const store = this.getStore;
    if (store) {
      delete store[studioId];
      this.updateStore(store);
    }
    this.updatedStore.next();
  }

  public numberOfItemsInStore(studioId?: any): number {
    const store = this.getStore;
    if (store) {
      let studios = Object.keys(store);

      if(this.userId && studioId) {
        studios = studios.filter((studio) => +studio === +studioId);
      }
      return studios.reduce((previousValue: number, studioId: string) => {
        const count = store[studioId].filter((item: StoreStockItem) => item.isChecked && item.quantityProduct).length;
        return previousValue + count;
      }, 0);
    }
    return 0;
  }

  private updateStore(data: any) {
    const store = JSON.stringify(data);
    localStorage.setItem('store_'+this.userId, store);
    this.updatedStore.next(data);
  }
}
