import { Component, ViewChild, ViewContainerRef, HostBinding } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { DialogService } from 'ui/lib/services/dialog.service';
import { DialogAnimation } from './wrapper.animation';

@Component({
  selector: 'ui-studio-dialog-wrapper',
  template: `
    <ng-template #dialogComponentOutlet></ng-template>
  `,
  styleUrls: ['./wrapper.component.scss'],
  animations: DialogAnimation,
})
export class DialogWrapperComponent implements AfterViewInit {
  @ViewChild('dialogComponentOutlet', { read: ViewContainerRef, static: true })
  private dialogComponentOutlet!: ViewContainerRef;

  @HostBinding('@parentPopupWindow')
  public get isHasDialog(): boolean {
    return Boolean(this.dialogComponentOutlet && this.dialogComponentOutlet.length);
  }

  constructor(private dialogService: DialogService) {}

  public ngAfterViewInit(): void {
    this.dialogService.setDialogContainer(this.dialogComponentOutlet);
  }
}
