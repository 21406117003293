import { Injectable } from '@angular/core';
import parsePhoneNumber from "libphonenumber-js";
import { CountryCode } from "libphonenumber-js/types";

@Injectable()
export class HelperService {

  public decimalNumber(value: any): number {
    let v = Number.parseFloat(value);
    if (Number.isNaN(v)) {
      v = 0;
    }
    const roundAmount = Math.round(v * 100) / 100;
    return parseFloat(roundAmount.toFixed(2));
  }

  formatInternationalPhoneNumber(phone: string, countryCode?: CountryCode) {
    if (!phone) {
      return null;
    }
    
    if (countryCode) {
      const phoneNumber = parsePhoneNumber(phone, countryCode);
      if (phoneNumber) {
        return phoneNumber.formatInternational();
      }
    } else {
      const phoneNumber = parsePhoneNumber(phone);
      if (phoneNumber) {
        return phoneNumber.formatInternational();
      }
    }

    //Filter only numbers from the input
    /*const cleaned = phone ? ('' + phone).replace(/\D/g, '') : '';
    if ((cleaned.length >= 10) && (cleaned.length <= 11)) {
      //Check if the input is of correct
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
    }*/

    return phone;
  }

  getPhoneURI(phone: string, countryCode?: CountryCode) {
    if (!phone) {
      return null;
    }

    if (countryCode) {
      const phoneNumber = parsePhoneNumber(phone, countryCode);
      if (phoneNumber) {
        return phoneNumber.getURI();
      }
    } else {
      const phoneNumber = parsePhoneNumber(phone);
      if (phoneNumber) {
        return phoneNumber.getURI();
      }
    }

    //Filter only numbers from the input
    const cleaned = phone ? ('' + phone).replace(/\D/g, '') : '';

    return ('tel:' + cleaned);
  }

}
