import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { SafeTypes } from 'ui';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(rawValue: unknown, type: SafeTypes): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    const value = String(rawValue ?? '');

    switch (type) {
      case SafeTypes.html:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SafeTypes.style:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SafeTypes.script:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SafeTypes.url:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SafeTypes.resourceUrl:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
